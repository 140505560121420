<template>
  <v-row>
    <v-col lg="4" md="6" sm="12" cols="12" class="mt--30" v-for="(service, i) in serviceContent" :key="i">  
      <div :class="`standard-service ${service.classe}`"> 
        <div class="thumbnai">
          <img :src="service.src" title="Sistema para Loteamento - scae" alt="sistema de gestão de loteamento"/>
        </div>
        <div class="content">
          <h3>{{ service.title }}</h3>
          <p>{{ service.desc }}</p>
        </div> 
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/servicos/empreendimento.svg"),
            title: "Gestão de Empreendimentos",
            desc: `Faça a gestão dos lotes vendidos, disponíveis e indisponíveis e veja como está o seu estoque de terrenos.`,
            classe: 'fadein-left'
          },
          { 
            src: require("../../assets/images/servicos/contratos.svg"),
            title: "Gestão de Contratos",
            desc: `Faça a gestão dos seus contratos e gere relatórios de parcelas pagas.`,
            classe: 'fadein-bottom'
          },
          {
            src: require("../../assets/images/servicos/regua.svg"),
            title: "Adm. Inadimplentes",
            desc: `Veja quantos contratos estão inadimplentes em qual fase da régua de inadimplência, faça acordos e tenha o histórico de tudo.`,
            classe: 'fadein-right'
          },
          {
            src: require("../../assets/images/servicos/compras.svg"),
            title: "Gestão de Compras",
            desc: `Faça o levantamento dos produtos e realize cotações de preços.`,
            classe: 'fadein-left'
          },
          {
            src: require("../../assets/images/servicos/financeiro.svg"),
            title: "Gestão Financeira",
            desc: `Faça a gestão do fluxo de caixa. Tire relatórios e tome as melhores decisões.`,
            classe: 'fadein-bottom'
          },
          {
            src: require("../../assets/images/servicos/etapas.svg"),
            title: "Gestão de obras",
            desc: `Cadastre as etapas do empreendimento e veja o orçado x realizado.`,
            classe: 'fadein-right'
          },
        ],
      };
    },
  };
</script>
