<template>
  <div> 
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer"
      v-model="drawer"
      fixed
      width="320"
    >   
      <v-list-item class="pa-5">
        <div class="logo">
          <img title="Sistema para loteamentos" alt="Software para gestão de loteamento"
            src="../../assets/images/logo/logoscae.png"
          />
        </div>
        <v-spacer></v-spacer>  
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="71"
      >
        <v-list>

          
          <v-list-item
            :ripple="false"
            v-for="(item) in items"
            :key="item.title"
            :to="item.to"  
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div class="about-button pt--5 mr-12">
            <a class="rn-button-style--4" target="_blank" title="Sistema para a gestão de loteamento"  href="https://api.whatsapp.com/send?phone=5521988020112&text=Olá! Vim pelo site e quero mais informações."> WhatsApp <v-icon color="white" large right>mdi-whatsapp</v-icon></a>
          </div> 
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
      
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar 
    style="height: 80px; padding: 10px"
      fixed
      class="header-one-page-nav"
    >
      <router-link to="/" class="logo sem-background">
       <!--<slot name="logo"></slot>--> 
        <slot name="logo-dark"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-spacer></v-spacer>
      <v-btn 
        icon
        class="ma-0 pa-0 hidden-md-and-up"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      >
      </v-btn>

      <!-- End mobile menu icon -->
      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto fadein">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="60"
        > 
        <template v-for="(item) in items">
          <v-btn :key="item.title" :to="item.to" link :ripple="false" plain class="scrollactive-item fadein" style="font-size: 15px;">
            {{ item.title }} 
          </v-btn>
        </template>

        <v-btn link text> 
          <a target="_blank" class="rn-button-style--4" title="Sistema para a gestão de loteamento"  href="https://api.whatsapp.com/send?phone=5521988020112&text=Olá! Vim pelo site e quero mais informações.">WhatsApp <v-icon large right>mdi-whatsapp</v-icon></a>
        </v-btn>
      </scrollactive>

      </v-toolbar-items>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data: () => ({
    drawer: false,
    items: [
      { title: "Home", to: "#home" },
      { title: "Serviços", to:"#serviços"},
      { title: "Dúvidas", to:"#questions"},
      { title: "Avaliações", to:"#opnions"},
      { title: "Sobre", to:"#sobre"},
      { title: "Contato", to: "#contato" },
    ],
    icon: "menu",
    closeIcon: "x",
  }),
  methods: {
    iconSvg(icon) { 
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
