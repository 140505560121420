<template>
  <div>
    <VueSlickCarousel  v-bind="settings">

      <div class="mt--15 mt_sm--5 mt_md--5"> 
        <div class="single-service service__style--5 large-size text-center">
          <div class="mt--10 service">
            <div class="content">  
              <v-icon class="icon" color="#CD7F32">fas fa-cloud</v-icon>
                <h1 class="heading-title-2">SCAE BRONZE</h1>
                <!-- <h1 class="heading-title">500 Lotes</h1> -->
                <!-- <p>Mensalidade R$ 500,00 + R$ 2,00 un</p> -->
                <!-- <p style="font-size:10px">(Até 500 Lotes)</p> -->
                
                <div class="justify-text">
                  <div >
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Até 500 lotes.</span>  
                  </div> 
                   <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> 10 Usuários onlines.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por e-mail.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por Whatsapp.</span>  
                  </div>  
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Régua de cobrança.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo do Financeiro.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Contratos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Módulo de Suprimentos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Módulo de Gestão de Obras.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Módulo de Gestão de Serviços Terceiros.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Treinamento ao vivo.</span>  
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div class="mt--15 mt_sm--5 mt_md--5"> 
        <div class="single-service service__style--5 large-size text-center">
          <div class="mt--10 service">
            <div class="content">  
              <v-icon class="icon" color="#C0C0C0">fas fa-rocket</v-icon>
                <h1 class="heading-title-3">SCAE PRATA</h1>
                <!-- <h1 class="heading-title">1000 Lotes</h1> -->
                <!-- <p>Mensalidade R$ 800,00 + R$ 1,30 un</p> -->
                <!-- <p style="font-size:10px">(Até 500 Lotes)</p> -->
                
                <div class="justify-text">
                  <div >
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Até 1000 lotes.</span>  
                  </div> 
                   <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> 30 Usuários onlines.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por e-mail.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por Whatsapp.</span>  
                  </div>  
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Régua de cobrança.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo do Financeiro.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Contratos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Suprimentos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Módulo de Gestão de Obras.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Módulo de Gestão de Serviços Terceiros.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="red">fas fa-times</v-icon>
                    <span class="span"> Treinamento ao vivo.</span>  
                  </div>
                </div>  
              </div>
          </div>
        </div>
      </div>

      <div class="mt--15 mt_sm--5 mt_md--5"> 
        <div class="single-service service__style--5 large-size text-center">
          <div class="mt--10 service">
            <div class="content">  
              <v-icon class="icon" color="#ffd700">mdi-crown</v-icon>
                <h1 class="heading-title-4">SCAE OURO</h1>
                <!-- <h1 class="heading-title">1000 Lotes</h1> -->
                <!-- <p>Mensalidade R$ 800,00 + R$ 1,30 un</p> -->
                <!-- <p style="font-size:10px">(Até 500 Lotes)</p> -->
                <div class="justify-text">
                  <div >
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Até 2000 lotes.</span>  
                  </div> 
                   <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> 80 Usuários onlines.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por e-mail.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por Whatsapp.</span>  
                  </div>  
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Régua de cobrança.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo do Financeiro.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Contratos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Suprimentos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Obras.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Serviços Terceiros.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Treinamento ao vivo.</span>  
                  </div>
                  <!-- <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Mensalidade à consultar</span>  
                  </div> -->
                </div>  
              </div>
          </div>
        </div>
      </div>

      <div class="mt--15 mt_sm--5 mt_md--5"> 
        <div class="single-service service__style--5 large-size text-center">
          <div class="mt--10 service">
            <div class="content">  
              <v-icon class="icon" color="#B9F2FF">mdi-diamond</v-icon>
              
                <h1 class="heading-title-5">SCAE PLATINUM</h1>
                <!-- <h1 class="heading-title">1000 Lotes</h1> -->
                <!-- <p>Mensalidade R$ 800,00 + R$ 1,30 un</p> -->
                <!-- <p style="font-size:10px">(Até 500 Lotes)</p> -->
                <div class="justify-text">
                  <div >
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Acima de 2000 lotes. </span>  
                  </div> 
                   <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Usuários ilimitados.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por e-mail.</span>  
                  </div> 
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Automação de envio de boletos por Whatsapp.</span>  
                  </div>  
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Régua de cobrança.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo do Financeiro.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Contratos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Suprimentos.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Obras.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Módulo de Gestão de Serviços Terceiros.</span>  
                  </div>
                  <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Treinamento ao vivo.</span>  
                  </div>
                  <!-- <div>
                    <v-icon size="10px" color="green">fas fa-check</v-icon>
                    <span class="span"> Mensalidade à consultar</span>  
                  </div> -->
                </div>  
              </div>
          </div>
        </div>
      </div>    
  </VueSlickCarousel>
</div>
</template>

<script>
  import VueSlickCarousel from "vue-slick-carousel";
  import feather from "feather-icons";
  export default {

    components: {
      VueSlickCarousel,
    },
    data() {
      return {
        serviceContent: [
          {
            subtile: "(Até 500 Lotes)",
            title: "Plano Standard",
            desc: "R$ 299,00 mensais  +",
            titleButton:"R$ 9,50",
            icon:"fas fa-cloud",
            value:"(De 501 Até 1000 recebimentos mensais)",
            itens: ["Administração do Loteamento", " Gestão de carteira de Recebíveis", "Gestão dos Contratos", "Cobrança Amigável"]
          },
          {
            subtile: "(De 501 até 1000 lotes)",
            title: "Plano Business",
            desc: "R$ 199,00 mensais  +",
            titleButton:"R$ 8,50",
            icon:"fas fa-rocket",
            value:"(De 501 Até 1000 recebimentos mensais)",
            itens: ["Administração do Loteamento", " Gestão de carteira de Recebíveis", "Gestão dos Contratos", "Cobrança Amigável"]
          },
          { 
            subtile: "(Acima de 1000 Lotes)",
            title: "Plano Corporate",
            desc: "___",
            titleButton:"R$ 7,50",
            icon:"fa fa-globe",
            value:"",
            itens: ["Administração do Loteamento", " Gestão de carteira de Recebíveis", "Gestão dos Contratos", "Cobrança Amigável"]
          },          
        ],
        settings: {
          "dots": true,
          "infinite": false,
          "slidesToShow": 4,
          "slidesToScroll": 1,
          "autoplay": false,
          "autoplaySpeed": 2000,
          "pauseOnDotsHover": true,
          "pauseOnFocus": true,
          "pauseOnHover": true,
          responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 1263,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 599,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        },
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
