<template>
    <div class="d-flex justify-center" style="background-color: #FAFAFA; padding: 50px;">
        <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
    <div class="text-h5 text-md-h4 pb-2 text-center">Experimente agora e revolucione suas vendas<i class="fa fa-arrow-down arrow-in-button" style="color:#f8824b"></i></div>
      <v-text-field
        v-model="cf_nome_da_loteadora"
        :counter="100"
        :rules="nameRules"
        label="Nome da Loteadora"
        required
      ></v-text-field>

      <v-text-field
        v-model="name"
        :counter="100"
        :rules="nameRules"
        label="Nome"
        required
      ></v-text-field>
  
      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-text-field
        v-model="number"
        :counter="19"
        :rules="numberRules"
        label="Telefone"
        v-mask="'+55 (##) #####-####'"
        required
      ></v-text-field>
  
      <v-btn
        :disabled="!valid"
        color="primary"
        class="mr-4"
        @click="onSubmit"
      >
        Enviar
      </v-btn>
      <v-alert v-if="showSuccessMessage" type="success" dismissible>
        Muito Obrigado! Seu E-mail foi enviado e vamos entrar em contato em breve.
      </v-alert>
    </v-form>
    </div> 
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import { mask } from 'vue-the-mask';

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    directives: { mask },
    data() {
      return {
        valid: false,
        showSuccessMessage: false,
        nameRules: [(v) => !!v || "Nome é obrigatório", (v) => v.length <= 100 || "Nome deve ter menos de 100 caracteres"],
        emailRules: [(v) => !!v || "E-mail é obrigatório", (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido"],
        numberRules: [(v) => !!v || "Número é obrigatório", (v) => v.length <= 19 || "Número de ter menos de 19 caracteres"],
      };
    },
    methods: {
      onSubmit() {
        if (this.$refs.form.validate()) {
        this.showSuccessMessage = true;
        setTimeout(() => {
          window.open("https://web.whatsapp.com/send?phone=+5521988020112&text=Ol%C3%A1%21+Vim+pelo+site+e+quero+saber+mais+sobre+a+SCAE+o+melhor+sistema+para+loteamentos+do+Brasil.", "_blank");
        }, 2000); 
        }
      },
    },
  };
</script>